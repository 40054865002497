import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const menuItems = [
  {
    title: "Halaman Utama",
    icon: "fa-solid fa-house-user",
    link: "/",
    submenu: []
  },
  {
    title: "Penyimpanan",
    icon: "fa-solid fa-hard-drive",
    link: "/cloud",
    submenu: []
  }
];

function Sidebar() {
  const navigate = useNavigate();
  const token = localStorage.getItem("auth_token");
  const [user, setUser] = useState(null); // State untuk menyimpan data user
  const [storageData, setStorageData] = useState({
    used_storage: 0,
    free_storage: 0
  });

  // Fungsi untuk mengambil data penyimpanan dari API
  const fetchStorageData = async () => {
    try {
      const response = await axios.get("/cloud-storage/usage", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setStorageData(response.data);
    } catch (error) {
      console.error("Gagal mengambil data penyimpanan:", error);
     
    }
  };

  // Fungsi untuk mengambil data pengguna dari endpoint /me
  const fetchUserData = async () => {
    try {
      const response = await axios.get("/me", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUser(response.data); // Simpan data pengguna di state user
    } catch (error) {
      console.error("Gagal mengambil data pengguna:", error);
     
    }
  };

  // Ambil data penyimpanan dan pengguna ketika komponen dimuat
  useEffect(() => {
    fetchStorageData();
    fetchUserData(); // Ambil data pengguna
  }, []);

  const handleLogout = async () => {
    try {
      Swal.fire({
        title: "Sedang memproses...",
        text: "Mohon tunggu...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const response = await axios.post(
        "/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      localStorage.removeItem("auth_token");
      localStorage.removeItem("user");

      Swal.fire("Logout Berhasil", "Anda telah keluar.", "success");

      navigate("/login");
    } catch (error) {
      console.error("Logout gagal:", error);
      Swal.fire("Error", "Logout gagal, coba lagi.", "error");
    }
  };

  // Fungsi untuk mengonversi ukuran penyimpanan
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 B";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const totalStorage = 2147483648; // Total storage dalam byte (2GB)
  const usedStorage = storageData.used_storage;
  const freeStorage = storageData.free_storage;
  const percentUsed = (usedStorage / totalStorage) * 100;

  return (
    <div className="iq-sidebar sidebar-default">
      <div className="iq-sidebar-logo d-flex align-items-center justify-content-between pb-0">
        <img
          src="https://smamutas.sch.id/assets/img/logo.png"
          className="img-fluid rounded-normal m-2"
          alt="logo"
        />
        <b className="pt-2 text-primary">
          Pusat Sistem Informasi Guru -{" "}
          <small className="font-weight-bold">SMA Muhammadiyah</small>
        </b>
        <div className="iq-menu-bt-sidebar pb-5">
          <i className="fa-solid fa-xmark wrapper-menu" />
        </div>
      </div>
      <div className="data-scrollbar" data-scroll={1}>
        <nav className="iq-sidebar-menu p-2">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            {menuItems.map((menuItem, index) => (
              <li
                key={index}
                className={menuItem.submenu.length ? "" : "active"}
              >
                <Link
                  to={menuItem.link}
                  className={menuItem.submenu.length ? "collapsed" : ""}
                >
                  <i className={menuItem.icon} />
                  <span>{menuItem.title}</span>
                </Link>
              </li>
            ))}

            {user &&
              (user.kode_pegawai === "SMAM-OPERATOR" ||
                user.kode_pegawai === "SMAM-KEPALA"  || user.kode_pegawai === "SMAM-14") && (
                <li>
                  <Link to="/verify/administrator" style={{ color: "Red" }}>
                    <i className="fa-solid fa-user-lock" />
                    <span>Halaman Admin</span>
                  </Link>
                </li>
              )}

            {token && (
              <li>
                <Link onClick={handleLogout} className="btn btn-link">
                  <i className="fa-solid fa-right-from-bracket" />
                  <span>Keluar</span>
                </Link>
              </li>
            )}
          </ul>
        </nav>
        <hr />
        <div className="sidebar-bottom">
          <h4 className="mb-3">
            <i className="fa-solid fa-cloud-arrow-down fa-fade text-primary mr-2" />
            Penyimpanan
          </h4>
          <p>
            {formatBytes(usedStorage)} / {formatBytes(totalStorage)} Terpakai
          </p>
          <div className="progress mb-3">
            <div
              className={`progress-bar 
      ${
        percentUsed < 50
          ? "bg-success"
          : percentUsed < 80
          ? "bg-warning"
          : "bg-danger"
      }`}
              role="progressbar"
              style={{ width: `${percentUsed}%`, transition: "width 2s" }}
              aria-valuenow={percentUsed}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              {percentUsed}%
            </div>
          </div>

          <p>
            {percentUsed.toFixed(2)}% Penuh - {formatBytes(freeStorage)} Tersisa
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
