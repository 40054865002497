import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

function LockAdmin() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null); // State untuk menyimpan data user
  const [password, setPassword] = useState(""); // State untuk password input
  const defaultPassword = "smam321401"; // Default password akses admin
  const token = localStorage.getItem("auth_token");

  // Fungsi untuk mengambil data pengguna dari endpoint /me
  const fetchUserData = async () => {
    try {
      const response = await axios.get("/me", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const userData = response.data;

      // Jika kode_pegawai bukan "SMAM-OPERATOR" atau "SMAM-KEPALA", navigasi ke halaman utama
      if (
        userData.kode_pegawai !== "SMAM-OPERATOR" &&
        userData.kode_pegawai !== "SMAM-KEPALA" &&
        userData.kode_pegawai !== "SMAM-14"
      ) {
        Swal.fire({
          icon: "error",
          title: "Akses ditolak",
          text: "Anda tidak memiliki hak akses ke halaman admin.",
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          navigate("/");
        });
      } else {
        setUser(userData); // Set user jika memiliki hak akses
      }
    } catch (error) {
      console.error("Gagal mengambil data pengguna:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Gagal mengambil data pengguna. Coba lagi.",
        showConfirmButton: false,
        timer: 2000
      }).then(() => {
        navigate("/");
      });
    }
  };

  // Ambil data pengguna saat komponen dimuat
  useEffect(() => {
    fetchUserData();
  }, []);

  // Fungsi untuk menangani login ketika submit
  const handleLogin = (e) => {
    e.preventDefault();

    // Cek apakah password cocok
    if (password === defaultPassword) {
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Kode akses benar, Anda akan diarahkan ke halaman admin.",
        showConfirmButton: false,
        timer: 2000
      }).then(() => {
        localStorage.setItem("admin_locked", true);
        navigate("/administrator");
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Kode akses salah, coba lagi.",
        showConfirmButton: false,
        timer: 2000
      });
    }
  };

  return (
    <div className="container h-100 login-content">
      <div className="row justify-content-center align-items-center height-self-center">
        <div className="col-md-5 col-sm-12 col-12 align-self-center">
          <div className="sign-user_card">
            <img
              src="https://smamutas.sch.id/assets/img/logo.png"
              width={150}
              alt="Logo"
            />
            <h4 className="mt-3 mb-3">
              Hallo !{" "}
              {user
                ? user.tenaga_pendidik
                  ? user.tenaga_pendidik.nama
                  : user.tenaga_kependidikan
                  ? user.tenaga_kependidikan.nama
                  : "Data tidak ditemukan."
                : "Memuat data..."}
            </h4>
            <p>Masukkan password akses untuk masuk halaman admin.</p>
            <form onSubmit={handleLogin}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="floating-label form-group">
                    <input
                      className="floating-input form-control"
                      type="password"
                      placeholder=" "
                      value={password}
                      onChange={(e) => setPassword(e.target.value)} // Set password dari input
                    />
                    <label>Kode Akses</label>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Masuk
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LockAdmin;
