import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function Profile() {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get("/me", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setProfile(response.data);
      } catch (err) {
        setError("Gagal memuat profil.");
      }
    };

    fetchProfile();
  }, []);

  const handleLogout = async () => {
    try {
      // Tampilkan SweetAlert dengan loading
      Swal.fire({
        title: "Sedang logout...",
        text: "Mohon tunggu sebentar",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false
      });

      const token = localStorage.getItem("auth_token");
      await axios.post(
        "/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      localStorage.removeItem("auth_token");
      Swal.close(); // Tutup SweetAlert ketika logout berhasil
      window.location.href = "/login"; // Redirect ke halaman login
    } catch (err) {
      console.error("Gagal logout", err);
      Swal.fire({
        icon: "error",
        title: "Logout gagal",
        text: "Terjadi kesalahan saat logout. Coba lagi nanti."
      });
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!profile) {
    return <p>Memuat profil...</p>;
  }

  const tenagaPendidik = profile.tenaga_pendidik;
  const tenagaKependidikan = profile.tenaga_kependidikan;

  // Mengambil foto profil dari tenaga pendidik dengan fallback
  const profilePicture = tenagaPendidik?.foto
    ? `https://smamutas.sch.id${tenagaPendidik.foto}`
    : "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg";

  // Mengambil data dari tenaga pendidik atau kependidikan
  const selectedTenaga = tenagaPendidik || tenagaKependidikan;

  return (
    <li className="nav-item nav-icon dropdown caption-content">
      <a
        href="#"
        className="search-toggle dropdown-toggle"
        id="dropdownMenuButton03"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div className="caption bg-primary line-height">
          <img src={profilePicture} alt="Profile" className="rounded-circle" />
        </div>
      </a>
      <div
        className="iq-sub-dropdown dropdown-menu"
        aria-labelledby="dropdownMenuButton03"
      >
        <div className="card mb-0">
          <div className="card-header d-flex justify-content-between align-items-center mb-0">
            <div className="header-title">
              <h4 className="card-title mb-0">Profil</h4>
            </div>
            <div className="close-data text-right badge badge-primary cursor-pointer">
              <i className="ri-close-fill" />
            </div>
          </div>
          <div className="card-body">
            <div className="profile-header">
              <div className="cover-container text-center">
                <div className="profile-icon mx-auto d-block">
                  <img
                    src={profilePicture}
                    alt="Foto"
                    className="rounded-circle"
                  />
                </div>
                <div className="profile-detail mt-3">
                  {tenagaPendidik ? (
                    <>
                      {profile.email}
                      <br />
                      {tenagaPendidik.nama}
                      <p>
                        {tenagaPendidik.jabatan} {tenagaPendidik.mata_pelajaran}
                      </p>
                    </>
                  ) : tenagaKependidikan ? (
                    <>
                      {tenagaKependidikan.nama}
                      <p>{tenagaKependidikan.jabatan}</p>
                    </>
                  ) : (
                    <p>Data jabatan tidak ditemukan.</p>
                  )}
                </div>
                <button className="btn btn-primary" onClick={handleLogout}>
                  Keluar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Profile;
