import React from "react";

function Home() {
  return (
    <div
      className="tab-pane fade show active"
      id="home-justify"
      role="tabpanel"
      aria-labelledby="home-tab-justify"
    >
      <p>Silahkan memilih menu yang tersedia diatas.</p>
    </div>
  );
}

export default Home;
