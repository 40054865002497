import React from "react";
import TerakhirDitambahkan from "./Components/TerakhirDitambahkan";
import DaftarFolder from "./Components/DaftarFolder";
import DaftarFile from "./Components/DaftarFile";

function Content() {
  return (
    <div className="content-page">
      <div className="container-fluid">
        <DaftarFolder />
        <DaftarFile />
        <TerakhirDitambahkan />
      </div>
    </div>
  );
}

export default Content;
