import React, { useState, useEffect } from "react";
import axios from "axios";

// Komponen untuk menampilkan jadwal tiap kelas
function JadwalItem({ keterangan, guru, mapel, isCurrent }) {
  return (
    <td style={{ backgroundColor: isCurrent ? "#d4edda" : "transparent" }}>
      {keterangan ? (
        <span>{keterangan}</span> // Menampilkan keterangan seperti "Istirahat" atau "Ekskul"
      ) : guru ? (
        <span>
          {guru} ({mapel})
        </span>
      ) : (
        <span>-</span> // Tampilkan "-" jika tidak ada guru atau mapel
      )}
    </td>
  );
}

function JadwalFull() {
  const [jadwal, setJadwal] = useState([]);
  const [error, setError] = useState(null);
  const [hariIni, setHariIni] = useState("");
  const [waktuSekarang, setWaktuSekarang] = useState(null);

  // Mendapatkan hari dan waktu sekarang
  useEffect(() => {
    const hariArray = [
      "MINGGU", "SENIN", "SELASA", "RABU", "KAMIS", "JUMAT", "SABTU"
    ];
    const tanggal = new Date();
    setHariIni(hariArray[tanggal.getDay()]);
    setWaktuSekarang(`${tanggal.getHours()}:${("0" + tanggal.getMinutes()).slice(-2)}`);
  }, []);

  // Mengambil data jadwal dari API
  useEffect(() => {
    const fetchJadwal = async () => {
      try {
        const response = await axios.get("/jadwal-pelajaran");
        setJadwal(response.data || []);
      } catch (err) {
        setError("Gagal memuat data jadwal.");
      }
    };

    fetchJadwal();
  }, []);

  // Filter jadwal berdasarkan hari ini
  const jadwalHariIni = jadwal.filter((item) => item.hari === hariIni);

// Fungsi untuk memeriksa apakah waktu sekarang berada dalam rentang waktu tertentu
const isWithinTimeRange = (startTime, endTime, currentTime) => {
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);
  const [currentHour, currentMinute] = currentTime.split(":").map(Number);

  // Membandingkan waktu mulai, waktu selesai, dan waktu sekarang
  const start = new Date();
  start.setHours(startHour, startMinute);

  const end = new Date();
  end.setHours(endHour, endMinute);

  const current = new Date();
  current.setHours(currentHour, currentMinute);

  return current >= start && current <= end;
};

// Cek apakah pelajaran saat ini sedang berlangsung
const isCurrentLesson = (waktuPelajaran) => {
  const [start, end] = waktuPelajaran.split(" - ");

  // Format waktu saat ini
  const now = new Date();
  const currentTime = now.getHours() + ":" + ("0" + now.getMinutes()).slice(-2); // Format HH:mm

  // Memeriksa apakah waktu sekarang berada dalam rentang waktu pelajaran
  return isWithinTimeRange(start, end, currentTime);
};


// Cek apakah pembelajaran hari ini telah selesai
const isPembelajaranSelesai = jadwalHariIni.length > 0 && (() => {
  const now = new Date();
  const currentTime = `${("0" + now.getHours()).slice(-2)}:${("0" + now.getMinutes()).slice(-2)}`; // Format HH:mm

  // Mendapatkan pelajaran terakhir
  const pelajaranTerakhir = jadwalHariIni[jadwalHariIni.length - 1];
  const [startLast, endLast] = pelajaranTerakhir.waktu.split(" - ");

  // Mengecek apakah waktu sekarang melebihi waktu akhir pelajaran terakhir
  return currentTime > endLast;
})();




  return (
    <div className="col-12">
      <div className="card card-block card-stretch card-height">
        <div className="card-body">
          <h5 className="mb-3 text-primary font-weight-bold text-center">
            Jadwal Pembelajaran Hari {hariIni}
          </h5>
          {error ? (
            <p className="text-danger text-center">{error}</p>
          ) : jadwalHariIni.length > 0 ? (
            <>
              {isPembelajaranSelesai ? (
                <p className="text-center font-weight-bold">
                  Pembelajaran hari ini telah selesai.
                </p>
              ) : (
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Jam</th>
                        <th>Waktu</th>
                        <th>Kelas X-1</th>
                        <th>Kelas X-2</th>
                        <th>Kelas X-3</th>
                        <th>Kelas XI MIPA</th>
                        <th>Kelas XI IPS-1</th>
                        <th>Kelas XII MIPA</th>
                        <th>Kelas XII IPS-1</th>
                        <th>Kelas XII IPS-2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jadwalHariIni.map((item) => (
                        <tr key={item.id}>
                          <td>{item.jam_ke}</td>
                          <td>{item.waktu}</td>
                          <JadwalItem
                            keterangan={item.keterangan}
                            guru={item.x_1_guru}
                            mapel={item.x_1_mapel}
                            isCurrent={isCurrentLesson(item.waktu)}
                          />
                          <JadwalItem
                            keterangan={item.keterangan}
                            guru={item.x_2_guru}
                            mapel={item.x_2_mapel}
                            isCurrent={isCurrentLesson(item.waktu)}
                          />
                          <JadwalItem
                            keterangan={item.keterangan}
                            guru={item.x_3_guru}
                            mapel={item.x_3_mapel}
                            isCurrent={isCurrentLesson(item.waktu)}
                          />
                          <JadwalItem
                            keterangan={item.keterangan}
                            guru={item.xi_mipa_guru}
                            mapel={item.xi_mipa_mapel}
                            isCurrent={isCurrentLesson(item.waktu)}
                          />
                          <JadwalItem
                            keterangan={item.keterangan}
                            guru={item.xi_ips_1_guru}
                            mapel={item.xi_ips_1_mapel}
                            isCurrent={isCurrentLesson(item.waktu)}
                          />
                          <JadwalItem
                            keterangan={item.keterangan}
                            guru={item.xii_mipa_guru}
                            mapel={item.xii_mipa_mapel}
                            isCurrent={isCurrentLesson(item.waktu)}
                          />
                          <JadwalItem
                            keterangan={item.keterangan}
                            guru={item.xii_ips_1_guru}
                            mapel={item.xii_ips_1_mapel}
                            isCurrent={isCurrentLesson(item.waktu)}
                          />
                          <JadwalItem
                            keterangan={item.keterangan}
                            guru={item.xii_ips_2_guru}
                            mapel={item.xii_ips_2_mapel}
                            isCurrent={isCurrentLesson(item.waktu)}
                          />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          ) : (
            <p className="text-center font-weight-bold">Tidak ada jadwal untuk hari ini.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default JadwalFull;
