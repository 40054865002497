import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import Menu from "./Components/Menu";
import Home from "./Components/Home";
import WebsiteBerita from "./Components/WebsiteBerita";
import WebsiteProfile from "./Components/WebsiteProfile";
import AksesPegawai from "./Components/AksesPegawai";
import DataPegawai from "./Components/DataPegawai";
import JadwalPelajaran from "./Components/JadwalPelajaran";
import DataPiket from "./Components/DataPiket";
import WebsitePPDB from "./Components/WebsitePPDB";
import WebsitePapanPrestasi from "./Components/WebsitePapanPrestasi";

function Content() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const token = localStorage.getItem("auth_token");
  const isLocked = localStorage.getItem("admin_locked"); // Cek apakah sudah melewati LockAdmin

  // Fungsi untuk mengambil data pengguna dari endpoint /me
  const fetchUserData = async () => {
    try {
      const response = await axios.get("/me", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const userData = response.data;

      // Cek apakah pengguna memiliki hak akses (SMAM-OPERATOR atau SMAM-KEPALA)
      if (
        userData.kode_pegawai !== "SMAM-OPERATOR" &&
        userData.kode_pegawai !== "SMAM-KEPALA" && 
        userData.kode_pegawai !== "SMAM-14"
      ) {
        Swal.fire({
          icon: "error",
          title: "Akses ditolak",
          text: "Anda tidak memiliki hak akses ke halaman admin.",
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          navigate("/"); // Jika tidak memiliki akses, arahkan ke halaman utama
        });
      } else {
        setUser(userData); // Set data pengguna jika memiliki hak akses
      }
    } catch (error) {
      console.error("Gagal mengambil data pengguna:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Gagal mengambil data pengguna. Coba lagi.",
        showConfirmButton: false,
        timer: 2000
      }).then(() => {
        navigate("/"); // Jika terjadi error, arahkan ke halaman utama
      });
    }
  };

  // Cek apakah sudah melewati LockAdmin, jika belum arahkan ke /verify/administrator
  useEffect(() => {
    if (!isLocked) {
      navigate("/verify/administrator");
    } else {
      fetchUserData();
    }
  }, [isLocked]);

  // Fungsi untuk mengunci halaman dan navigasi ke halaman verifikasi
  const handleLockPage = () => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda akan mengunci halaman dan keluar dari admin.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, kunci!",
      cancelButtonText: "Batal"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("admin_locked"); // Hapus status lock dari localStorage
        Swal.fire({
          icon: "success",
          title: "Halaman terkunci",
          text: "Anda akan diarahkan ke halaman verifikasi.",
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          navigate("/verify/administrator"); // Arahkan ke halaman verifikasi
        });
      }
    });
  };

  if (!user) {
    return <div className="spinner" />; // Tampilkan loading sampai data user tersedia
  }

  return (
    <div className="content-page">
      <div className="container-fluid">
        <center>
          <div className="col-lg-6 col-sm-12">
            <div className="card mb-0">
              <div className="card-body">
                <img
                  src="https://smamutas.sch.id/assets/img/logo.png"
                  width={150}
                  alt="#"
                />
                <h4 className="card-title font-weight-bold mt-4">
                  Halaman Administrator
                </h4>
                <b className="card-text text-primary font-weight-bold">
                  SMA Muhammadiyah Tasikmalaya
                </b>
                <div className="mt-4">
                  <button
                    className="btn btn-danger btn-sm font-weight-bold"
                    onClick={handleLockPage}
                  >
                    <i className="fa-solid fa-lock" />
                    Kunci Halaman
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <Menu />
                <div className="tab-content mt-5" id="administratorTab">
                  <Home />
                  <WebsiteBerita />
                  <WebsitePPDB />
                  <WebsitePapanPrestasi />
                  <WebsiteProfile />
                  <AksesPegawai />
                  <DataPegawai />
                  <JadwalPelajaran />
                  <DataPiket />
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
}

export default Content;
