import React, { useState, useEffect } from "react";
import axios from "axios";

function TMT() {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // State untuk loading

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get("/me", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setProfile(response.data);
      } catch (err) {
        setError("Gagal memuat data TMT.");
      } finally {
        setLoading(false); // Set loading ke false setelah data diterima
      }
    };

    fetchProfile();
  }, []);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-block card-stretch card-height">
        <div className="card-body text-center">
          <h5 className="mb-3 text-primary font-weight-bold">
            Terhitung Mulai Tanggal
          </h5>

          {loading ? (
            // Tampilkan spinner saat sedang memuat data
            <span class="loader"></span>
          ) : (
            profile && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={60}
                  height={48}
                  fill="green"
                  viewBox="0 0 448 512"
                >
                  <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l80 0 0 56-80 0 0-56zm0 104l80 0 0 64-80 0 0-64zm128 0l96 0 0 64-96 0 0-64zm144 0l80 0 0 64-80 0 0-64zm80-48l-80 0 0-56 80 0 0 56zm0 160l0 40c0 8.8-7.2 16-16 16l-64 0 0-56 80 0zm-128 0l0 56-96 0 0-56 96 0zm-144 0l0 56-64 0c-8.8 0-16-7.2-16-16l0-40 80 0zM272 248l-96 0 0-56 96 0 0 56z" />
                </svg>

                <h6 className="mb-2 mt-4">
                  {(() => {
                    const tenagaPendidik = profile.tenaga_pendidik;
                    const tenagaKependidikan = profile.tenaga_kependidikan;

                    const tmt = tenagaPendidik
                      ? tenagaPendidik.tmt
                      : tenagaKependidikan
                      ? tenagaKependidikan.tmt
                      : null;

                    if (!tmt) {
                      return "Data TMT tidak ditemukan.";
                    }

                    // Ubah string tanggal menjadi objek Date
                    const tmtDate = new Date(tmt);

                    // Format tanggal menjadi "01 July 2024"
                    const formattedTMT = new Intl.DateTimeFormat("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric"
                    }).format(tmtDate);

                    // Hitung selisih waktu antara TMT dan hari ini
                    const today = new Date();
                    const years = today.getFullYear() - tmtDate.getFullYear();
                    let months = today.getMonth() - tmtDate.getMonth();
                    let days = today.getDate() - tmtDate.getDate();

                    if (days < 0) {
                      months--;
                      days += new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        0
                      ).getDate();
                    }

                    if (months < 0) {
                      months += 12;
                    }

                    const elapsedTime = `${years} tahun, ${months} bulan, ${days} hari`;

                    return (
                      <>
                        {formattedTMT}
                        <p className="text-muted">Sudah {elapsedTime}.</p>
                      </>
                    );
                  })()}
                </h6>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default TMT;
